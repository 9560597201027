import {
  parse,
  parseISO,
  format,
  isBefore,
  isSameDay,
  getYear,
  getMonth,
  getDate,
  getHours,
  getMinutes,
  differenceInMonths,
} from 'date-fns'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import ja from 'date-fns/locale/ja'

const DEFAULT_TIME_ZONE = 'Asia/Tokyo'

// 2023年03月07日(火) 06:00 - 12:00
export const formatDatetimeDurationLong = (
  startAt: string,
  endAt: string,
): string => {
  const start = parseISO(startAt)
  const end = parseISO(endAt)
  const startFormat = 'yyyy年MM月dd日(E) HH:mm'
  const endFormat = isSameDay(start, end) ? 'HH:mm' : '翌 HH:mm'
  return `${format(start, startFormat, { locale: ja })} - ${format(
    end,
    endFormat,
    { locale: ja },
  )}`
}

// 03月07日(火) 06:00 - 12:00
export const formatDatetimeDurationShort = (
  startAt: string,
  endAt: string,
): string => {
  const start = parseISO(startAt)
  const end = parseISO(endAt)
  const startFormat = 'MM月dd日(E) HH:mm'
  const endFormat = isSameDay(start, end) ? 'HH:mm' : '翌 HH:mm'
  return `${format(start, startFormat, { locale: ja })} - ${format(
    end,
    endFormat,
    { locale: ja },
  )}`
}

export const parseDate = (dateString: string) => {
  return parseISO(dateString)
}

export const parseDateString = (dateString: string) => {
  const date = parseISO(dateString)

  return {
    year: getYear(date),
    month: getMonth(date) + 1, // ライブラリの仕様として1月が0からとなる
    date: getDate(date),
    hour: getHours(date),
    minute: getMinutes(date),
  }
}

export const parseDateStringToDate = (dateString: string) => {
  return parseISO(dateString)
}

export const parseDateStringToJstDate = (dateString: string) => {
  // JST以外の時間になっていた場合、JSTの時間に直す
  return utcToZonedTime(parseISO(dateString), DEFAULT_TIME_ZONE)
}

export const calcAgeWithDateString = (dateString: string) => {
  const now = new Date()
  const birthDay = parseISO(dateString)

  if (isBefore(now, birthDay)) {
    return '妊娠中'
  }

  const differenceMonth = differenceInMonths(now, birthDay)
  return `${Math.floor(differenceMonth / 12)}歳 ${differenceMonth % 12}ヶ月`
}

export const calcAgeString = (year?: number, month?: number, day?: number) => {
  if (!(year && month && day)) {
    return undefined
  }

  const now = new Date()
  const birthDay = new Date(year, month - 1, day)

  if (isBefore(now, birthDay)) {
    return '妊娠中'
  }

  const differenceMonth = differenceInMonths(now, birthDay)
  return `${Math.floor(differenceMonth / 12)}歳 ${differenceMonth % 12}ヶ月`
}

export const calcAgeStringWithDateString = (dateString: string) => {
  const now = new Date()
  const birthDay = parseISO(dateString)

  if (isBefore(now, birthDay)) {
    return '妊娠中'
  }

  const differenceMonth = differenceInMonths(now, birthDay)
  return `${Math.floor(differenceMonth / 12)}歳 ${differenceMonth % 12}ヶ月`
}

export const dateToStringWithFormat = (
  date: Date,
  formatString: string,
  locale?: Locale,
) =>
  !!locale
    ? formatInTimeZone(date, DEFAULT_TIME_ZONE, formatString, { locale })
    : formatInTimeZone(date, DEFAULT_TIME_ZONE, formatString)

export const formatDateString = (
  dateString: string,
  formatString: string,
  locale?: Locale,
) => {
  return dateToStringWithFormat(parseISO(dateString), formatString, locale)
}
