export const makeNumberArray = (start: number, end: number) => {
  return [...Array<number>(end - start + 1)].map((_, i) => start + i)
}

export const makeZeroPaddingNumberArray = (
  start: number,
  end: number,
  digit: number,
) => {
  return [...Array<number>(end - start + 1)].map((_, i) =>
    (start + i).toString().padStart(digit, '0'),
  )
}

export const findIndexWithArray = <T>(hour: T, array: T[]) => {
  const index = array.indexOf(hour)
  return index < 0 ? 0 : index
}
