import * as React from 'react'
import * as classNames from 'classnames'

import * as Style from './style.css'

export interface IProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'type' | 'className'> {
  isError?: boolean
  isErrorAlert?: boolean
}

export const InputText = React.forwardRef<HTMLInputElement, IProps>(
  ({ isError, isErrorAlert, ...props }, ref) => (
    <input
      type="text"
      className={classNames({
        [Style.InputText]: true,
        [Style.InputTextError]: isError,
        [Style.InputTextErrorAlert]: isErrorAlert,
      })}
      {...props}
      ref={ref}
    />
  ),
)
